
import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging } from 'devextreme-react/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/ui/button';
import { Link } from 'react-router-dom';
import Form, { Item, Label } from 'devextreme-react/form';

import { msalAuth } from '../../msal/MsalAuthProvider';

export class CaseQueueContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.formElement = React.createRef();

        this.state = {
            loading: false,
            userIsSysAdmin: false,
            userId: 0,

            filters: {
                active: true,
                agedout: false,
                closed: false,
                released: false,
                transferred: false,
                unassigned: false,
                intransfer: false,
                caseworthy: false,
                searchText: ''
            }
        };
    }

    customDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            console.log('Loading: ' + loadOptions);

            loadOptions.userData["active"] = this.state.filters.active;
            loadOptions.userData["agedout"] = this.state.filters.agedout;
            loadOptions.userData["closed"] = this.state.filters.closed;
            loadOptions.userData["released"] = this.state.filters.released;
            loadOptions.userData["transferred"] = this.state.filters.transferred;
            loadOptions.userData["unassigned"] = this.state.filters.unassigned;
            loadOptions.userData["intransfer"] = this.state.filters.intransfer;
            loadOptions.userData["caseworthy"] = this.state.filters.caseworthy;
            loadOptions.userData["text"] = this.state.filters.searchText;
        },
        load: (loadOptions) => {
            console.log('Load', loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&';
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }
            console.log(queryString);

            return FetchAPIPromise('Cases', queryString);
        }
    });

    SearchCases = (e) => {
        this.dataGrid.instance.refresh();
    }

    GetData = async () => {
        var userRoles = msalAuth.getAccount().idTokenClaims.roles;
        var isUserSystemAdmin = (
            ((userRoles.find(element => element === 'SystemAdmin')) == 'SystemAdmin')
            || ((userRoles.find(element => element === 'SystemAdmin_Training') == 'SystemAdmin_Training') && window.location.hostname == 'minerva.theprovidenciagroup.com')
        );
        /*
        alert(userRoles.find(element => element === 'SystemAdmin'));
        alert(((userRoles.find(element => element === 'SystemAdmin')) == 'SystemAdmin'));
        alert(userRoles.find(element => element === 'SystemAdmin_Training'));
        alert((userRoles.find(element => element === 'SystemAdmin_Training') == 'SystemAdmin_Training'));
        alert(window.location.hostname);
        alert(isUserSystemAdmin);
        */
        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    //componentDidMount = async () => {
    //    var employeeData = await this.FetchAPI('Employee/GetEmployeeByEmail/' + msalAuth.getAccount().userName);
    //    console.log(employeeData.employeeId + " Testing");

    //    await this.setState({
    //        userId: employeeData.employeeId
    //    });
    //}

    render() {

        return (
            <div className="container-fluid">
                <h1>Case List</h1>
                
                <Form
                    ref={ref => this.editFormControl = ref}
                    formData={this.state.filters} colCount={3}>
                    <Item itemType="group" colCount={7} colSpan={2}>
                        <Item dataField="active" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="agedout" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} >
                            <Label text="Aged Out" />
                        </Item>
                        <Item dataField="closed" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="released" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="transferred" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="unassigned" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }} />
                        <Item dataField="intransfer" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }}>
                            <Label text="In-Transfer" />
                        </Item>
                        <Item dataField="caseworthy" editorType="dxCheckBox" editorOptions={{ onValueChanged: this.SearchCases }}>
                            <Label text="Migrated to CaseWorthy" />
                        </Item>
                    </Item>
                    <Item colSpan={1} dataField="searchText" editorType="dxTextBox" editorOptions={{ placeholder: 'Search for Case Number, A Number, First Name, or Last Name', onEnterKey: this.SearchCases, showClearButton: true }}>
                        <Label visible={false} />
                    </Item>
                </Form>
                <br />

                <DataGrid dataSource={this.customDataSource} ref={ref => this.dataGrid = ref}
                    showBorders={true} allowColumnResizing={true}
                    showRowLines={true} rowAlternationEnabled={true}>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column dataField="caseNumber" cellRender={function (options) { return (<Link to={`/case-information/${options.row.data.id}`}>{options.value}</Link>); }} />
                    <Column dataField="aNumber" caption="A Number" />
                    <Column dataField="firstName" />
                    <Column dataField="lastName" />
                    <Column dataField="dob" caption="DOB" format="MM/d/yyyy" dataType="date" />
                    <Column dataField="relationshipGroupID" caption="Relationship Group" />
                    <Column dataField="caseStatusName" caption="Case Status" />
                    <Column dataField="seniorLeadDisplayName" caption="Senior Lead" />
                    <Column dataField="leadCaseManagerDisplayName" caption="Lead Case Manager" />
                    <Column dataField="caseManagerDisplayName" caption="Case Manager" />
                    <Column dataField="facilityName" caption="Shelter" />
                </DataGrid>

                {/*{this.state.userId === 0 &&*/}
                {/*    <footer style={{ backgroundColor: "yellow", width: "100%", justifyContent: 'center' }}>*/}
                {/*        <div>*/}
                {/*            <h1>User is not in database</h1>*/}
                {/*        </div>*/}
                {/*    </footer>*/}
                {/*}*/}
            </div>
        );
    }
}