import React from 'react';
import BaseComponent from '../BaseComponent.js';
import DataGrid, { Column, Paging, FilterRow, Pager  } from 'devextreme-react/ui/data-grid';
import FileUploader from 'devextreme-react/file-uploader';
import Button from 'devextreme-react/ui/button';

import { msalAuth } from '../../msal/MsalAuthProvider';
import { msalConfig } from '../../msal/MsalConfig';

export class EmployeeImportContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            token: '',
            importResult: null,
            importMessage: null,
            employeesToImport: [],
            importCount: null
        };
    }

    GetData = async () => {
        console.log('get data');

        var requestScope = msalConfig.clientId + '/.default';

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest)

        console.log(token.accessToken);

        this.setState({
            token: token.accessToken
        });
    }

    ImportRecords = async (e) => {
        await this.setState({
            loading: true
        });

        try {
            var recordsToImport = this.state.employeesToImport.filter((item) => item.willImport);

            const result = await this.PostAPI('Employee/BulkImport', recordsToImport);

            if (result.status == 1) {
                alert(result.message);

                this.props.history.push('/');
                this.props.history.push('/employees');
            }
            else {
                alert('Error encountered during import.');
            }
        }
        finally {

        }
    }

    onBeforeSend = (e) => {
        console.log(e);

        e.request.setRequestHeader('Authorization', `Bearer ${this.state.token}`);
    }

    onUploaded = (e) => {
        console.log('Uploaded', e);

        var response = JSON.parse(e.request.response);

        this.setState({
            importResult: response.status,
            importMessage: response.message,
            importErrorMessage: response.errorMessage,
            employeesToImport: response.employees,
            importCount: response.willImportCountEmployees
        });

        this.fileUpload.instance.reset();
    }

    render() {

        let id = 0;
        function createData(type, info) {
            id += 1;
            return { id, type, info };
        }

        function NewLineText(props) {
            const text = props;
            const newLine = text.split('\n').map(str => <div>{str}</div>);
            return newLine;
        }

        function createTable(tableData, header1, header2) {
            return (
                <div>
                    <table>
                        <tr key={0}>
                            <th>{header1}</th>
                            <th>{header2}</th>
                        </tr>
                        {tableData.map(row => (
                            <tr key={row.id}>
                                <td>{row.type}</td>
                                <td>{row.info}</td>
                            </tr>
                        ))}
                    </table >
                </div>
            )
        }

        let requiredFieldsRows = [
            createData('First Name', 'Less than 50 characters'),
            createData('Last Name', 'Less than 50 characters'),
            createData('Employee No', 'Must be unique, no duplicates in the file and must not already exist in Vesta. If it is a number with less than 6 digits, zeros will be added to the front automatically. Letters are allowed, special characters are not allowed.'),
            createData('Email', 'Less than 100 characters')
        ];

        let requiredOptionalRows = [
            createData('Middle Name', 'Less than 50 characters'),
            createData('Active', NewLineText('Supported Values: TRUE, FALSE \n If empty, default value is FALSE'))
        ];

        return (
            <div className="container-fluid">
                <h1>Import Employees</h1>
                <h2>Step 1: Download a blank template</h2>
                <p>
                    <a href='Blank Template Employees.xlsx' download>Blank Template Employees.xlsx</a>
                </p>
                <h3>Required fields</h3>
                {createTable(requiredFieldsRows, 'Column Name', 'Guidance')}
                <br />
                
                <h3>Optional fields</h3>
                {createTable(requiredOptionalRows, 'Column Name', 'Guidance')}
                <br />
                
                <h2>Step 2: Select and upload the file for validation</h2>
                <p>After preparing the Employees, select the Import file, and upload.</p>
                <div className="fileuploader-container">
                    <FileUploader ref={ref => this.fileUpload = ref} selectButtonText="Select Import File" labelText="" uploadUrl={`/API/Employee/BulkUpload`} name="file" onBeforeSend={this.onBeforeSend} onUploaded={this.onUploaded} uploadMode="useButtons" multiple={false} />
                </div>

                {this.state.importResult &&
                    <div>
                    <p title={this.state.importErrorMessage}>{this.state.importMessage}</p>
                    <br />
                    <DataGrid dataSource={this.state.employeesToImport} ref={ref => this.dataGrid = ref}
                        showBorders={true} allowColumnResizing={true}>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />
                        <Column dataField="willImport" caption="Will Import?" cellRender={function (options) { return (<img width='20px' height='20px' src={options.row.data.willImport ? 'yesbutton.png' : 'nobutton.png'} />); }} defaultFilterValue={false} />
                        <Column dataField="firstName" caption="First Name*" cellRender={function (options) { return (<div title={options.row.data.firstNameMessage} style={{ backgroundColor: `${options.row.data.firstNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.firstName}</div>); }} />
                        <Column dataField="middleName" caption="Middle Name" cellRender={function (options) { return (<div title={options.row.data.middleNameMessage} style={{ backgroundColor: `${options.row.data.middleNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.middleName}</div>); }} />
                        <Column dataField="lastName" caption="Last Name*" cellRender={function (options) { return (<div title={options.row.data.lastNameMessage} style={{ backgroundColor: `${options.row.data.lastNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.lastName}</div>); }} />
                        <Column dataField="employeeNumber" caption="Employee No*" cellRender={function (options) { return (<div title={options.row.data.employeeNumberMessage} style={{ backgroundColor: `${options.row.data.employeeNumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.employeeNumber}</div>); }} />
                        <Column dataField="email" caption="Email" cellRender={function (options) { return (<div title={options.row.data.emailMessage} style={{ backgroundColor: `${options.row.data.emailValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.email}</div>); }} />
                        <Column dataField="active" caption="Active" cellRender={function (options) { return (<div title={options.row.data.activeMessage} style={{ backgroundColor: `${options.row.data.activeValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.active}</div>); }} />
                    </DataGrid>


                    </div>
                }

                <br />
                <br />
                <h2>Step 3: Import Employees</h2>
                <p>After reviewing the validation results, import Employees in the file. If there are errors, fix and re-upload the file.</p>

                {this.state.importCount > 0 &&
                    <Button className="button" text="Import Employees in the File" type="success" onClick={this.ImportRecords} />
                }

                <br />
                <br />
            </div>
        );
    }
}