
import React from 'react';
import * as moment from 'moment';
//import authService from './api-authorization/AuthorizeService'
import { msalAuth } from '../msal/MsalAuthProvider';
import { msalConfig } from '../msal/MsalConfig';

export var FetchAPIPromise = (apiName, parameters) => {
    var requestScope = msalConfig.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                return fetch('API/' + apiName + (parameters ? '?' + parameters : ''), {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token.accessToken}` },
                    redirect: 'error'
                })
                    .then(response => response.json())
                    // For Debug Only
                    //.then(json => { console.log(json); return json; })
                    .catch(() => { throw 'System error related to the API and/or database. Click on the Support menu link and email support.'} );
            }
        });
}

export var PostAPIPromise = (apiName, objectToPost) => {
    var requestScope = msalConfig.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    Date.prototype.toISOString = function () {
        var pad = function (num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

        return this.getFullYear() +
            '-' + pad(this.getMonth() + 1) +
            '-' + pad(this.getDate()) +
            'T' + pad(this.getHours()) +
            ':' + pad(this.getMinutes());
    };

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                return fetch('API/' + apiName, {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify(objectToPost),
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Authorization': `Bearer ${token.accessToken}`
                    },
                    redirect: 'error'
                })
                    .then(response => response.json())
                    .then((result) => {
                        // console.log(result);
                        if (result.status == -1) {
                            alert('Failed acquiring silent token. Please try again later. If this continues, please take a screenshot and send to VestaSupport@theprovidenciagroup.com.');
                        }

                        return result;
                    })
                    .catch(() => { throw 'Network error' });
            }
        });
}

export var PutAPIPromise = (apiName, objectToPost) => {
    var requestScope = msalConfig.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                return fetch('API/' + apiName, {
                    method: 'PUT',
                    credentials: 'include',
                    body: JSON.stringify(objectToPost),
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Authorization': `Bearer ${token.accessToken}`
                    },
                    redirect: 'error'
                })
                    .then(response => response.json())
                    .then((result) => {
                        if (result.status == -1) {
                            alert('Failed acquiring silent token for API put. Please try again later. If this continues, please take a screenshot and send to VestaSupport@theprovidenciagroup.com.');
                        }
                    })
                    .catch(() => { throw 'Network error' });
            }
        });
}

export var DeleteAPIPromise = (apiName, parameters) => {
    var requestScope = msalConfig.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                console.log('Token returned:' + token);

                return fetch('API/' + apiName + (parameters ? '?' + parameters : ''), {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token.accessToken}` },
                    redirect: 'error',
                    method: 'DELETE'
                })
                    .then(response => response.json())
                    .then((result) => {
                        if (result.status == -1) {
                            alert('Failed acquiring silent token for API delete. Please try again later. If this continues, please take a screenshot and send to VestaSupport@theprovidenciagroup.com.');
                        }

                        return result;
                    })
                    .catch(() => { throw 'Network error' });
            }
        });
}

export default class BaseComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        console.log('Fetching data');

        this.setState({
            loading: true
        });

        console.log('Getting drop down data');
        await this.GetDropDownData();

        await this.GetData();

        this.setState({
            loading: false
        });
    }

    GetDropDownData = async () => {
    }

    GetData = async () => {
    }

    FetchAPI = async (apiName) => {
        var requestScope = msalConfig.clientId + '/.default';

        const accessTokenRequest = {
            scopes: [requestScope]
        }
        var token = null;
        try {
            token = await msalAuth.acquireTokenSilent(accessTokenRequest);
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            token = await msalAuth.acquireTokenPopup(accessTokenRequest);
        }

        //console.log("Token is: ");
        //console.log(token.accessToken);

        const response = await fetch('API/' + apiName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token.accessToken}` },
            redirect: 'error'
        });

        //console.log(response);

        return await response.json();
    }


    PostAPI = async (apiName, objectToPost) => {

        console.log(JSON.stringify(objectToPost));

        var requestScope = msalConfig.clientId + '/.default';

        Date.prototype.toISOString = function () {
            var pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };

            return this.getFullYear() +
                '-' + pad(this.getMonth() + 1) +
                '-' + pad(this.getDate()) +
                'T' + pad(this.getHours()) +
                ':' + pad(this.getMinutes());
        };

        const accessTokenRequest = {
            scopes: [requestScope]
        }
        var token = null;
        try {
            token = await msalAuth.acquireTokenSilent(accessTokenRequest);
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            token = await msalAuth.acquireTokenPopup(accessTokenRequest);
        }

        var response = await fetch('API/' + apiName, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(objectToPost),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token.accessToken}`
            },
            redirect: 'error'
        });

        console.log(response);

        return await response.json();
    }

    RawPostAPI = async (apiName, objectToPost) => {

        console.log(JSON.stringify(objectToPost));

        var requestScope = msalConfig.clientId + '/.default';

        Date.prototype.toISOString = function () {
            var pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };

            return this.getFullYear() +
                '-' + pad(this.getMonth() + 1) +
                '-' + pad(this.getDate()) +
                'T' + pad(this.getHours()) +
                ':' + pad(this.getMinutes());
        };

        const accessTokenRequest = {
            scopes: [requestScope]
        }
        var token = null;
        try {
            token = await msalAuth.acquireTokenSilent(accessTokenRequest);
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            token = await msalAuth.acquireTokenPopup(accessTokenRequest);
        }

        var response = await fetch('API/' + apiName, {
            method: 'GET',
            credentials: 'include',
            body: JSON.stringify(objectToPost),
            responseType: "blob",
            headers: {
                'Authorization': `Bearer ${token.accessToken}`,
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf'
            },
            redirect: 'error'
        });

        console.log(response);

        return response;
    }

    DeleteAPI = async (apiName, objectToPost) => {

        console.log(JSON.stringify(objectToPost));

        var requestScope = msalConfig.clientId + '/.default';

        const accessTokenRequest = {
            scopes: [requestScope]
        }
        var token = null;
        try {
            token = await msalAuth.acquireTokenSilent(accessTokenRequest);
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            token = await msalAuth.acquireTokenPopup(accessTokenRequest);
        }

        var response = await fetch('API/' + apiName, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(objectToPost),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token.accessToken}`
            },
            redirect: 'error'
        });

        console.log(response);

        return await response.json();
    }

    formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            //Remove the matched extension code
            //Change this to format for any country code.
            let intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }

        return str;
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }

        return (null);
    }

    dateTimeOffsetConverter(dateTimeOffset) {
        console.log("Incoming DateTime Conversion value: " + dateTimeOffset);

        var minDate = moment("0001-01-01T00:00:00");

        var returnValue = dateTimeOffset;

        if (dateTimeOffset) {
            console.log(dateTimeOffset);
            console.log(moment(dateTimeOffset).diff(minDate));
            if (moment(dateTimeOffset).diff(minDate) == 0) {
                // Currently, time is non-null default, convert to null
                returnValue = null;
            }
            else {
                returnValue = moment(dateTimeOffset).toDate();
            }
        }

        return returnValue;
    }

    handleDateTimeOffsetChange = (e, prop, model) => {
        console.log("DateTime Offset Change Occurring: " + e);
        if (e) {
            this.updateProperty(model, prop, moment(e).toDate());
        }
        else {
            this.updateProperty(model, prop, null);
        }
    }

    handleDateTimeOffsetIndexChange = (e, prop, model, index) => {
        console.log("DateTime Offset Change Occurring: " + e + " index: " + index);
        if (e) {
            this.updateIndexProperty(model, prop, index, moment(e).toDate());
        }
        else {
            this.updateIndexProperty(model, prop, index, null);
        }
    }

    updateProperty = (obj, prop, value) => {
        let newState = Object.assign({}, this.state);
        newState[obj][prop] = value;
        this.setState(newState);
    }

    updateIndexProperty = (obj, prop, index, value) => {
        let newState = Object.assign({}, this.state);
        newState[obj][index][prop] = value;
        this.setState(newState);
    }

    CreateDropDownOptions(array, valueProp, textProp, filter) {

        filter = filter || "item => 1 == 1";

        // console.log("DropDown filter: " + filter);

        return array.filter(eval(filter)).map((option) =>
            <option value={(valueProp ? option[valueProp] : option)}>{(textProp ? option[textProp] : option)}</option>
        );
    }

    // TODO ABG: Potentially encapsulate this in a more base-classed way
    FilterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        if (row[id] !== null && typeof row[id] === 'string') {
            return (
                row[id] !== undefined ?
                    String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
            )
        } else if (row[id] !== null) {
            let re = new RegExp("^" + filter.value, "i");
            let match = (String(row[id]).match(re) === null) ? false : true;
            return match;
        } else {
            return false;
        }
    }
}