import React, { Component } from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';

export class HelpContainer extends BaseComponent {

    render() {

        let id = 0;
        function createData(type, info) {
            id += 1;
            return { id, type, info };
        }

        function createTable(tableData, header1, header2) {
            return (
                <div>
                    <table>
                        <tr key={0}>
                            <th>{header1}</th>
                            <th>{header2}</th>
                        </tr>
                        {tableData.map(row => (
                            <tr key={row.id}>
                                <td>{row.type}</td>
                                <td>{row.info}</td>
                            </tr>
                        ))}
                    </table >
                </div>
            )
        }

        const h2Style = {
            color: 'blue'
        };

        const h3Style = {
            color: 'green'
        };

        let usersGroupsRolesRows = [
            createData('Vesta_SystemAdmins', 'To be added'),
            createData('Vesta_CaseManagers', 'Exists'),
            createData('Vesta_Location_DullesAirport', 'To be added'),
            createData('Vesta_Location_FortMcCoy', 'To be added'),
            createData('Vesta_Location_FortBliss', 'To be added'),
            createData('Vesta_Location_PecosEIS', 'To be added')
        ];

        let authRows = [
            createData('Production', 'https://vesta.theprovidenciagroup.com/ '),
            createData('QA/UAT', 'https://janus.theprovidenciagroup.com/ '),
            createData('Local Dev', 'https://127.0.0.1:44329/ ')
        ];

        let appRolesRows = [
            createData('System Admin', 'Exists'),
            createData('Case Manager', 'Exists'),
            createData('\u00A0', '\u00A0'),
            createData('Location – Dulles Airport', 'To be added, [Location_DullesAirport] Visibility to Dulles Airport only'),
            createData('Location – Fort McCoy', 'To be added, [Location_FortMcCoy] Visibility to Fort McCoy only'),
            createData('Location – Fort Bliss', 'To be added, [Location_FortBliss] Visibility to Fort Bliss only'),
            createData('Location – Pecos EIS', 'To be added, [Location_PecosEIS] Visibility to Pecos EIS only')
        ];

        let assignGroupLinkRows = [
            createData('Vesta_SystemAdmins', 'Exists'),
            createData('Vesta_CaseManagers', 'Exists'),
            createData('\u00A0', '\u00A0'),
            createData('Existing Users', 'Need to be added to the appropriate group and then removed from direct User to App Role assignment'),
            createData('\u00A0', '\u00A0'),
            createData('Vesta_Location_DullesAirport', 'To be added Location – Dulles Airport'),
            createData('Vesta_Location_FortMcCoy', 'To be added Location – Fort McCoy'),
            createData('Vesta_Location_FortBliss', 'To be added Location – Fort Bliss'),
            createData('Vesta_Location_PecosEIS', 'To be added Location – Pecos EIS')
        ];

        let usersGroupsRolesLink = 'https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupsManagementMenuBlade/AllGroups';
        let vestaCMExampleLink = 'https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Members/groupId/87608651-a82d-4c8b-a6cb-f924e9256ffb';
        let authenticationLink = 'https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/appId/654b1455-ef96-4d55-8982-6035bb62b63a/isMSAApp/';
        let appRolesLink = 'https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/AppRoles/appId/654b1455-ef96-4d55-8982-6035bb62b63a/isMSAApp/';
        let assignGroupLink = 'https://portal.azure.com/#blade/Microsoft_AAD_IAM/ManagedAppMenuBlade/Users/appId/654b1455-ef96-4d55-8982-6035bb62b63a/objectId/1ec5ab9e-891a-4fd4-8f99-25ee03fbfbc1';

        return (
            <div className="container">
                <h1>Help</h1>
                <br />
                <h1>Users, Groups, Roles, and Application Setup</h1>
                <br/>
                <h2 style={h2Style}>Users, Groups, and Roles Administration</h2>
                <ul>
                    <li><a href={usersGroupsRolesLink}>{usersGroupsRolesLink}</a></li>
                </ul>
                {createTable(usersGroupsRolesRows, 'Name', 'Notes')}
                <br />
                <h3 style={h3Style}>Vesta_CaseManagers Example</h3>
                <ul>
                    <li><a href={vestaCMExampleLink}>{vestaCMExampleLink}</a></li>
                </ul>
                <picture>
                    <img src={"./vestacm.png"} />
                </picture>
                <br />
                <br />
                <h2 style={h2Style}>Setup - Users/Groups, Roles, and Application</h2>
                <h3 style={h3Style}>Authentication</h3>
                <ul>
                    <li><a href={authenticationLink}>{authenticationLink}</a></li>
                </ul>
                {createTable(authRows, 'Environment', 'URL')}
                <br />
                <picture>
                    <img src={"./vestaAuth.png"} />
                </picture>
                <br /><br />
                <h3 style={h3Style}>App Roles</h3>
                <ul>
                    <li><a href={appRolesLink}>{appRolesLink}</a></li>
                </ul>
                {createTable(appRolesRows, 'Display Name','Notes')}
                <br />
                <picture>
                    <img src={"./vestaAppRoles.png"} />
                </picture>
                <br /><br />
                <h2 style={h2Style}>Assign Groups to App Roles</h2>
                <a href={assignGroupLink}>{assignGroupLink}</a>
                {createTable(assignGroupLinkRows, 'Display Name', 'Role Assigned')}
                <br />
                <picture>
                    <img src={"./vestaAssignGroups.png"} />
                </picture>
                <br /><br /><br />
            </div>
        );
    }
}