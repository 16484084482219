
import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, Paging, FilterRow } from 'devextreme-react/ui/data-grid';
import { Link } from 'react-router-dom';
import PieChart, { Legend, Series, Label, Connector, HoverStyle, Title, Font, Size } from 'devextreme-react/pie-chart';
import { CheckBox } from 'devextreme-react/check-box';

export class CaseDashboardContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            caseMetrics: {},
            loading: false,
            filters: {
                active: true,
                agedout: false,
                closed: false,
                released: false,
                transferred: false,
                unassigned: false,
                intransfer: true,
                caseworthy: false,
                sponsorCategory: ''
            }

        };
    }

    pointClickHandler = (arg) => {
        var sponsor;
        if (arg.target.isSelected()) {
            arg.target.clearSelection();
            sponsor = '';
        }
        else {
            arg.target.select();
            sponsor = arg.target.data.sponsorCategory;
        }
        console.log('target', arg.target);

        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    sponsorCategory: sponsor
                }
            })
        );

        this.dataGrid.instance.refresh();
    }

    handleChange = (event) => {
        console.log(event);
        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    [event.element.id]: !state.filters[event.element.id]
                }
            })
        );

        this.dataGrid.instance.refresh();
    };


    GetData = async () => {
        var metrics = await this.FetchAPI('Cases/CaseDashboardMetrics?active=' + this.state.filters.active
            + '&agedout=' + this.state.filters.agedout
            + '&closed=' + this.state.filters.closed
            + '&released=' + this.state.filters.released
            + '&transferred=' + this.state.filters.transferred
            + '&unassigned=' + this.state.filters.unassigned
            + '&intransfer=' + this.state.filters.intransfer
            + '&caseworthy=' + this.state.filters.caseworthy);
        console.log('test', metrics);

        await this.setState({
            caseMetrics: metrics
        })
    }

    activeCasesDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            console.log('Loading: ' + loadOptions);

            loadOptions.userData["active"] = this.state.filters.active;
            loadOptions.userData["agedout"] = this.state.filters.agedout;
            loadOptions.userData["closed"] = this.state.filters.closed;
            loadOptions.userData["released"] = this.state.filters.released;
            loadOptions.userData["transferred"] = this.state.filters.transferred;
            loadOptions.userData["unassigned"] = this.state.filters.unassigned;
            loadOptions.userData["intransfer"] = this.state.filters.intransfer;
            loadOptions.userData["caseworthy"] = this.state.filters.caseworthy;
            loadOptions.userData["sponsorCategory"] = this.state.filters.sponsorCategory;
        },
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&';
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }
            console.log(queryString);


            return FetchAPIPromise('Cases/GetActiveCasesByEmployee', queryString);
        },
        onLoaded: (loadOptions) => {
            console.log('loaded', loadOptions);
            this.GetData();
        }
    });

    render() {

        return (
            <div className="container-fluid">
                <h1>My Cases</h1>
                <div className="row">
                    <PieChart
                        id="pie"
                        type="doughnut"
                        palette="Soft Pastel"
                        className="text-center col-sm-4"
                        dataSource={this.state.caseMetrics.sponsorCategoryCounts}
                        onPointClick={this.pointClickHandler}
                    >
                        <Title text="Sponsor Category" horizontalAlignment="center">
                            <Font size="30" weight="700" />
                        </Title>
                        <Series argumentField="sponsorCategory" valueField="count">
                            <Label visible={true}>
                                <Connector visible={true} />
                            </Label>
                            <HoverStyle color="#ffd700" />
                        </Series>
                        <Legend
                            margin={0}
                            horizontalAlignment="right"
                            verticalAlignment="top"
                        />
                        <Size height={300} width={300} />
                    </PieChart>
                </div>
                <div className="row">
                    <div className="mr-auto ml-3 form-inline">
                        Case Status:{' '}&nbsp;
                        <CheckBox value={this.state.filters.active} id="active" text="Active" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filters.intransfer} id="intransfer" text="In-Transfer" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filters.released} id="released" text="Released" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filters.transferred} id="transferred" text="Transferred" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filters.agedout} id="agedout" text="Aged Out" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filters.closed} id="closed" text="Closed" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filters.unassigned} id="unassigned" text="Unassigned" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filters.caseworthy} id="caseworthy" text="Migrated to CaseWorthy" onValueChanged={this.handleChange} />&nbsp;
                    </div>
                </div>

                <br />

                <div className="container-fluid">
                    <DataGrid
                        dataSource={this.activeCasesDataSource}
                        ref={ref => this.dataGrid = ref}
                        showBorders={true} allowColumnResizing={true}
                        showRowLines={true} rowAlternationEnabled={true}>
                        <FilterRow visible={true} />
                        <Paging enabled={false} />

                        <Column dataField="caseNumber" cellRender={function (options) { return (<Link to={`/case-information/${options.row.data.id}`}>{options.value}</Link>); }} />
                        <Column dataField="aNumber" caption="A Number" />
                        <Column dataField="firstName" />
                        <Column dataField="lastName" />
                        <Column dataField="dob" caption="DOB" format="MM/d/yyyy" dataType="date" />
                        <Column dataField="relationshipGroupID" caption="Relationship Group" />
                        <Column dataField="caseStatusName" caption="Case Status" />
                        <Column dataField="seniorLeadDisplayName" caption="Senior Lead" />
                        <Column dataField="leadCaseManagerDisplayName" caption="Lead Case Manager" />
                        <Column dataField="caseManagerDisplayName" caption="Case Manager" />
                        <Column dataField="facilityName" caption="Shelter" />
                        </DataGrid>
                </div>
                <br />
            </div>
        );
    }
}