import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import { msalAuth } from '../msal/MsalAuthProvider';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        var userRoles = msalAuth.getAccount().idTokenClaims.roles;

        var host = window.location.hostname;
        var environmentIndicator;
        var environmentName;

        if (host == 'vesta.theprovidenciagroup.com') {
            environmentIndicator = 'lightgray';
            environmentName = '';
        } else if (host == 'janus.theprovidenciagroup.com') {
            environmentIndicator = 'yellow';
            environmentName = '(Janus)';
        } else if (host == 'minerva.theprovidenciagroup.com') {
            environmentIndicator = '#e7e08b';
            environmentName = '(Minerva)';
        } else if (host == '127.0.0.1') {
            environmentIndicator = 'lightblue';
            environmentName = '(Dev)';
        } else {
            environmentIndicator = 'red';
            environmentName = '(Unknown)';
        }

        return (
            <header className="navbar fixed-top" style={{ backgroundColor: environmentIndicator, justifyContent: 'center' }}>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Vesta { environmentName }</NavbarBrand>

                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />

                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {(
                                    ((userRoles.find(element => element === 'SystemAdmin')) == 'SystemAdmin')
                                    || ((userRoles.find(element => element === 'SystemAdmin_Training') == 'SystemAdmin_Training') && window.location.hostname == 'minerva.theprovidenciagroup.com')
                                ) &&
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className="Alt-DropdownMenu" caret size="md">
                                            Admin
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/employees">Employees</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/facilities">Facilities</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/languages">Languages</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/nationalities">Nationalities</NavLink>
                                            </NavItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                }
                                {userRoles.find(element => element === 'CaseManager') &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/cases">Case List</NavLink>
                                    </NavItem>
                                }
                                {userRoles.find(element => element === 'CaseManager') &&
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/cases-dashboard">My Cases</NavLink>
                                    </NavItem>
                                }
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/support">Support</NavLink>
                                </NavItem>
                                {/*<LoginMenu />*/}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}