import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Link } from 'react-router-dom';

import { msalAuth } from '../msal/MsalAuthProvider';
import { User } from 'oidc-client';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        var userName = msalAuth.getAccount().name;
        var userUserName = msalAuth.getAccount().userName;
        var userRoles = "";

        msalAuth.getAccount().idTokenClaims.roles.forEach(AddRole);

        function AddRole(value) {
            userRoles += "-" + value + "\r\n";
        }

        var userEmployeeId = ""; //pull from the database
        //var caseData = await this.FetchAPI('Employee/' + '3');

        var hint = "Email: " + userUserName + "\r\n" + "Roles:\r\n" + userRoles;

        var host = window.location.hostname;
        var environmentIndicator;
        var environmentName;

        if (host == 'vesta.theprovidenciagroup.com') {
            environmentIndicator = 'lightgray';
            environmentName= '';
        } else if (host == 'janus.theprovidenciagroup.com') {
            environmentIndicator = 'yellow';
            environmentName = '(Janus)';
        } else if (host == 'minerva.theprovidenciagroup.com') {
            environmentIndicator = '#e7e08b';
            environmentName = '(Minerva)';
        } else if (host == '127.0.0.1') {
            environmentIndicator = 'lightblue';
            environmentName = '(Dev)';
        } else {
            environmentIndicator = 'red';
            environmentName = '(Unknown)';
        }

        return (
            <div>
                <NavMenu />
                <Container style={{ paddingBottom: '50px', paddingTop: '100px' }} fluid={true}>
                    {this.props.children}
                </Container>
                <footer className="navbar fixed-bottom" style={{ backgroundColor: environmentIndicator, width: "100%", justifyContent: 'center' }}>
                    <div title={hint}>
                        Vesta { environmentName } <Link to="/support">0.4.10.2</Link> &#169; 2023 Providencia. All Rights Reserved. Welcome Back, {userName}
                    </div>
                </footer>
            </div>
        );
    }
}
