
//"homepage": "https://pluto.mvminc.com/Vesta",
//"homepage": "https://vesta.theprovidencegroup.com",

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.greenmist.css';
import 'devexpress-gantt/dist/dx-gantt.css';

import React, { Component } from 'react';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Support } from './components/Support';

import { withAuth, msalAuth } from './msal/MsalAuthProvider';

import './custom.css'
import { EmployeeListContainer } from './components/Employee/EmployeeListContainer';
import { EmployeeEditContainer } from './components/Employee/EmployeeEditContainer';

import { CaseQueueContainer } from './components/CaseManagement/CaseQueueContainer';
import { CaseEditContainer } from './components/CaseManagement/CaseEditContainer';
import { CaseScheduleContainer } from './components/CaseManagement/CaseScheduleContainer';
import { CaseDashboardContainer } from './components/CaseManagement/CaseDashboardContainer';
import { CaseImportContainer } from './components/CaseManagement/CaseImportContainer';

import { FacilityContainer } from './components/Admin/FacilityContainer';
import { FacilityImportContainer } from './components/Admin/FacilityImportContainer';
import { DictionaryLanguageContainer } from './components/Admin/DictionaryLanguageContainer';
import { DictionaryNationalityContainer } from './components/Admin/DictionaryNationalityContainer'
import { HelpContainer } from './components/Admin/HelpContainer'
import { EmployeeImportContainer } from './components/Employee/EmployeeImportContainer';
import { ReleaseNotesListContainer } from './components/Admin/ReleaseNotesListContainer';
import { ReleaseNotesImportContainer } from './components/Admin/ReleaseNotesImportContainer';

class RootApp extends Component {
    render() {
        //console.log('Rendering');

        var userRoles = msalAuth.getAccount().idTokenClaims.roles;

        const userRoutes = [];

        if (userRoles.find(element => element === 'CaseManager')) {
            userRoutes.push(<Route path='/cases' component={CaseQueueContainer} />);
            userRoutes.push(<Route path='/create-case' component={CaseEditContainer} />);
            userRoutes.push(<Route path='/case-information/:id' component={CaseEditContainer} />);
            userRoutes.push(<Route path='/cases-schedule' component={CaseScheduleContainer} />);
            userRoutes.push(<Route path='/cases-dashboard' component={CaseDashboardContainer} />);
        }

        if (
            ((userRoles.find(element => element === 'SystemAdmin')) == 'SystemAdmin')
            || ((userRoles.find(element => element === 'SystemAdmin_Training') == 'SystemAdmin_Training') && window.location.hostname == 'minerva.theprovidenciagroup.com')
        ) {
            userRoutes.push(<Route path='/employees' component={EmployeeListContainer} />);
            userRoutes.push(<Route path='/employee/:id?' component={EmployeeEditContainer} />);
            userRoutes.push(<Route path='/facilities' component={FacilityContainer} />);
            userRoutes.push(<Route path='/languages' component={DictionaryLanguageContainer} />);
            userRoutes.push(<Route path='/nationalities' component={DictionaryNationalityContainer} />);
            userRoutes.push(<Route path='/help' component={HelpContainer} />);
            userRoutes.push(<Route path='/import-cases' component={CaseImportContainer} />);
            userRoutes.push(<Route path='/import-employees' component={EmployeeImportContainer} />);
            userRoutes.push(<Route path='/release-notes' component={ReleaseNotesListContainer} />);
            userRoutes.push(<Route path='/import-releaseNotes' component={ReleaseNotesImportContainer} />);
            userRoutes.push(<Route path='/import-facilities' component={FacilityImportContainer} />);
        }
        userRoutes.push(<Route path='/support' component={Support} />);

        var defaultPage;
        if (userRoles.find(element => element === 'Location_All')) {
            defaultPage = "/support";
        } else {
            defaultPage = "/support";
        }

        return (
            <Layout>
                {userRoutes}

                <Route
                    exact
                    path="/"
                    render={() => {
                        return (
                            <Redirect to={defaultPage} />
                        )
                    }}
                />

            </Layout>
        );
    }
}

export const App = withAuth(RootApp);