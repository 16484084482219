import React, { Component } from 'react';

import { msalAuth } from '../msal/MsalAuthProvider';


export class Support extends Component {

    render() {
        return (
            <div className="container">
                <div className="migration">
                    <h1>CaseWorthy Migration</h1>
                    <p>We are moving to the CaseWorthy platform for Case Management. It will replace the incumbent system, Vesta, and provide more features to support the growing scale needs of the Sponsor Services contract. This will be a one-time migration of Vesta assets to CaseWorthy.</p>
                    <ul>
                        <li>The new Case Management Platform: <a href="https://app.caseworthy.com/KGPStrategies_Prod.caseworthy" target="_blank">CaseWorthy</a></li>
                        <li>Migration Info and FAQs: <a href="https://mvminccloud.sharepoint.com/sites/ORRCaseManagementResources/SitePages/CaseWorthy-User-Support-Resources.aspx" target="_blank">ORR Case Management Resources</a></li>
                    </ul>
                </div>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h2>Contact Us</h2>
                <p>For any errors, support issues, feedback, or comments, please send an email to: <a href="mailto: VestaSupport@theprovidenciagroup.com">VestaSupport@theprovidenciagroup.com</a>.</p>
                <hr />
                <h2>Release Notes</h2>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.10.2</h3>
                <p>Release Date: 02/27/2023</p>

                <h4>Features</h4>
                <ul>
                    <li>Case: Add "Migrated to CaseWorthy" filter (#1010)</li>
                    <li>Admins: Hide "Import Cases" and "Add New Case" (#1008, #1009)</li>
                    <li>Default loads the Support page (#1012)</li>
                    <li>For Cases with status "Migrated to CW"
                        <ul>
                            <li>Case: Hide "Save" and "Delete" (#1014, #1013)</li>
                            <li>Case - Milestones: Hide "Edit" and "Clear" (#1016, #1015)</li>
                            <li>Case - Sponsor Demographics: Hide "Save Sponsor Info" (#1021)</li>
                        </ul>
                    </li>
                </ul>

                <hr />

                <h3>Vesta 0.4.9.2</h3>
                <p>Release Date: 10/14/2022</p>

                <h4>Features</h4>
                <ul>
                    <li>Update Milestone Transfer Recommended Options (#760)</li>
                    <li>Move the "Save Sponsor Info" up for more clarity (#764)</li>
                    <li>Throw a warning or notification to show Cases that are reassigned to another Shelter, it creates a shadow account that causes some confusion (#892)</li>
                    <li>On Milestone Save, if error, recommend to shorten the comments. (#905)</li>
                </ul>

                <h4>Known Issues</h4>
                <ul>
                    <li>Expand max length fields for Facilities (#756)</li>
                </ul>

                <h4>Reporting Updates</h4>
                <ul>
                    <li>Create reports for Active Cases for better performance (#730)</li>
                    <li>Daily Report - Add new milestone Values, Move "Transfer Recommended" (legacy) to the end (#758)</li>
                </ul>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.8.2</h3>
                <p>Release Date: 09/02/2022</p>

                <h4>Features</h4>

                <b>Move Transfer Recommended as a Milestone (#736)</b>
                <p>Remove the Transfer Recommended toggle in the UC Demographics and move it to a Milestone before Case Review completed.</p>

                <b>The "Save Sponsor Info" and "Save Sponsor Info" buttons have been moved (#764)</b>
                <p>Buttons are now more visible and are more intuitive to use when updating Case Info.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.7.2</h3>
                <p>Release Date: 08/05/2022</p>

                <h4>Features</h4>

                <b>Add a tab for related Cases that match Relationship Group (#700)</b>
                <p>Display Cases that have the same Relationship Group.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.6.3</h3>
                <p>Release Date: 07/29/2022</p>

                <h4>Features</h4>

                <b>Adjust the restriction for Event Date Time to allow "future" dates (#731)</b>
                <p>When creating or editing a milestone, the event date time will now support up to +4 hours in the future from the current time zone of the user.</p>

                <h4>Reporting Updates</h4>

                <b>Monthly Billing Report (#675)</b>
                <p>Update from monthly calendar updates to billing cycle from the 16th to the 15th.</p>

                <b>Daily Totals Report (#677)</b>
                <p>Split up reports into separate pages for improved performance.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.5.2</h3>
                <p>Release Date: 06/03/2022</p>

                <h4>Features</h4>

                <b>Default Landing Page (#533)</b>
                <p>On initial web page load, users part of the "Location All" group will land on Case List and other users will land on My Cases</p>

                <h4>Reporting Updates</h4>

                <b>Daily Report (#624)</b>
                <p>Transfer Required has been replaced with Transfer Recommended.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.4.3</h3>
                <p>Release Date: 05/28/2022</p>

                <h4>Features</h4>

                <b>My Cases Default Filter Change (#635)</b>
                <p>"Active" and "In-Transfer" are checked by default</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.4.2</h3>
                <p>Release Date: 05/20/2022</p>

                <h4>Features</h4>

                <b>Case Management Transfer Recommended Field (#623)</b>
                <p>The Runaway field has been renamed to Transfer Recommended</p>

                <b>Case Imports Handle Transfers (#591)</b>
                <p>Duplicate A#'s were blocking the shelter transfer process when importing cases from ORR.  New cases will be created if a transfer is detected, while the old case A# is updated.</p>

                <h4>Reporting Updates</h4>

                <b>Operational Forensics > Employees by Shelter Update (#595)</b>
                <p>A new column "Average LOS" has been added.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.3.3</h3>
                <p>Release Date: 04/29/2022</p>

                <h4>Features</h4>

                <b>New milestone added to Case Imports (#590)</b>
                <p>The Initial UC interview with CM milestone has been added to the Case Import template.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.3.2</h3>
                <p>Release Date: 04/22/2022</p>

                <h4>Features</h4>

                <b>Shelter and Employee lookups added to the Case Import instructions (#530)</b>
                <p>During the import cases process, shelters and employees can now be identified.</p>

                <b>New role to view all shelters added to Vesta (#565)</b>
                <p>System Administrators can now add employees to the Location_All group enabling a user to view all shelters and their corresponding cases.  This can be accessed via the Facilities page.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}

                <hr />

                <h3>Vesta 0.4.3.1</h3>
                <p>Release Date: 04/15/2022</p>

                <h4>Features</h4>

                <b>New Shelters Added (#564)</b>
                <p>Vesta now fully supports the new shelter:
                    <ul>
                        <li>Mercy First</li>
                        <li>SWK Quetzal</li>
                        <li>SWK Sunzal</li>
                        <li>SWK Montezuma</li>
                        <li>SWK Norma Linda</li>
                        <li>SWK Trail House</li>
                        <li>SWK Casa Padre</li>
                        <li>SWK Campbell</li>
                        <li>SWK Presidente</li>
                        <li>Rite of Passage LTFC</li>
                    </ul>
                </p>

                <b>Case Assignment Security Implementation (#522)</b>
                <p>Case assignments for Senior Lead Case Manager, Lead Case Manager and Case Manager can only be changed by a Vesta Administrator.</p>

                <h4>Known Issues</h4>

                <b>Case Import Fix (#531)</b>
                <p>A fix has been applied to ensure the Relationship Group ID and Support Staff ID is captured during import.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}

                <hr />

                <h3>Vesta 0.4.2.8</h3>
                <p>Release Date: 04/05/2022</p>

                <h4>Features</h4>

                <b>Three new shelter locations added (#528)</b>
                <p>Vesta now fully supports the new shelter:
                    <ul>
                        <li>Berkshire Farm Sheltered Hearts</li>
                        <li>Holy Family Institute</li>
                        <li>Cayuga Centers</li>
                    </ul>
                </p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.2.7</h3>
                <p>Release Date: 04/01/2022</p>

                <h4>Features</h4>

                <b>New shelter location added (#526)</b>
                <p>Vesta now fully supports the new shelter: Rite of Passage Canyon</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.2.6</h3>
                <p>Release Date: 03/11/2022</p>

                <h4>Features</h4>

                <b>New Case Status Option : In-Transfer (#502)</b>
                <p>The Case Status has been updated to include In-Transfer as an option.</p>

                <b>Case Status now supports In-Transfer (#505)</b>
                <p>In-Transfer has been added to the Case Status options. Case List & My Cases views now support the new Case Status filter.</p>

                {/*<b>Case Summary Field Now Available (#447)</b>*/}
                {/*<p>Vesta now supports a Case Summary field that enables users to provide comments relating to a specific case.  The field is limited to 2000 characters.</p>*/}

                <b>New shelter location added (#515)</b>
                <p>Vesta now fully supports the new shelter: A New Leaf</p>

                <h4>Known Issues</h4>

                <b>My Cases Sponsor Category Pie Chart Filtering Issue (#514)</b>
                <p>Filtering by Sponsor Category via the pie chart in relation to Case Status has now been fixed.</p>

                <h4>Reporting Updates</h4>

                <b>New Report: Employees by Shelter (#458)</b>
                <p>A new report has been created detailed employees by Shelter and their case loads.</p>

                <b>Latest Case Milestone Calculation  (#490)</b>
                <p>The Daily Report has been updated to improve the latest milestone calculation.</p>

                <b>Daily Totals Report by Category Report Update (#496)</b>
                <p>The Daily Totals by Category report had been updated to include a Case Summary Submitted to Case Coordination section.</p>

                <b>New Report: LCM Review (#503)</b>
                <p>A new report has been created Lead Case Manager Review. It shows a simple case census based on Categories as well as breakdown of cases expected to be submitted.</p>

                {/*<h4>Miscellaneous</h4>*/}

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.4.1.5</h3>
                <p>Release Date: 02/18/2022</p>

                <h4>Features</h4>

                <b>Key Milestone Decisions Updates (#245)</b>
                <p>Decisions for the Milestones below have been updated</p>
                <ul>
                    <li>Lead CM decision on case summary</li>
                    <li>Case Coordination decision made</li>
                    <li>FFS release decision made</li>
                    <li>Follow-up FFS release decision made</li>
                </ul>

                <h4>Reporting Updates</h4>

                <b>Update Power BI reports for new Milestone Decisions (#272)</b>
                <p>Report changes for the new Milestone Decisions changes.</p>
                <ul>
                    <li>Daily Report</li>
                    <li>Daily Totals Report</li>
                    <li>Detailed Daily Totals</li>
                </ul>

                <b>Case Age Calculation - Transfers calculation to include discharge dates (#386)</b>
                <p>Case Age Calculation not uses the new discharge date.</p>

                <h4>Miscellaneous</h4>

                <b>Add gridlines to all the tables under "Admin" (#489)</b>
                <p>Improve grid color consistency.</p>

                <b>Facilities - Edit/Delete column (#485)</b>
                <p>The main shelters\facilities list has been refreshed to make it easier to read and edit the list. </p>

                <b>First/Last Name Formatting (#477)</b>
                <p>Vesta now removes any spaces before and after the first and last names of an individual. </p>

                <b>A Number Formatting (#473)</b>
                <p>Vesta now removes any spaces before and after the A Number to ensure formatting consistency. </p>

                <b>Case Management Support Staff Field (#459)</b>
                <p>The DES field has been renamed to Support Staff. </p>

                <b>Household Fingerprints Required Renamed (#460)</b>
                <p>The Household Fingerprints Required field has been renamed to HHM Fingerprints Required. </p>

                <b>Case Information UI Changes (#462)</b>
                <p>The main Vesta Case Management user interface has been refreshed based on user feedback.  The user can switch between Sponsor Demographics and Case Milestones while maintaining UC Demographics and Case Assignments focus. </p>

                <b>Case Import Instructions Updated (#424)</b>
                <p>The Vesta Case Import template and page have been updated.  The template now contains instructions, requirements, and examples. </p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />
                
                <h3>Vesta 0.3.12.3</h3>
                <p>Release Date: 01/28/2022</p>

                <h4>Features</h4>

                <b>Relationship Group ID field now added to UC Demographics. (#280)</b>
                <p>Vesta now supports Relationship Group IDs.&nbsp; The UC demographics section has been updated to support the new field as well as the case import template.</p>

                <b>Active Employees only displayed in drop down lists.  (#382)</b>
                <p>Only active Employee ID's will be listed in Senior Lead, Lead Case Manager, Case Manager and Data Entry Specialist drop down lists.&nbsp; Likewise Case Imports will validate if the Employee ID is active in Vesta before import.</p>

                <b>Picture in Portal flag removed from UC Case Data. (#397)</b>
                <p>The Picture in Portal field has been removed from the UC Case Data section as it has become redundant.</p>

                <b>Facility Administration now includes links to the Azure Portal and the M365 Admin Center (#414)</b>
                <p>You can can manage members by using the Azure & M365 portals directly from Vesta</p>

                <h4>Reporting Updates</h4>

                <b>Shelter (Location) filtering now supports daily & operational reports. (#298)</b>
                <p>All Daily Reports and Operational Reports now support Shelter (Location) filtering.</p>

                <b>New Relationship Group ID Report (#422)</b>
                <p>Vesta now supports a Power BI Daily Report that displays the new Relationship Group ID.</p>

                <h4>Miscellaneous</h4>

                <b>Case Import guidance updates. (#419)</b>
                <p>The Case Import guidance page has been updated to include the Relationship Group ID field.</p>

                <b>Import Case validation grid updates. (#420)</b>
                <p>The 4 helper fields (Senior Lead Name, Lead Case Manager, Case Manager &amp; Data Entry Specialist) have been removed from the validation grid when importing new cases.</p>

                <b>Admin page changes (#425)</b>
                <p>The Facilities and Employees Administration pages have been re-formatted.</p>

                <hr />
                {/*-----------------------------------------------------------------------------------------------------------*/}

                <h3>Vesta 0.3.11.9 Hotfix 4</h3>
                <p>Release Date: 01/20/2022</p>

                {/*Features*/}
                <h4>Features</h4>

                <b>New shelter locations added</b>
                <p>Vesta now fully supports the following new shelters:
                    <ol>
                        <li>SWK Kokopelli</li>
                        <li>SWK Amanacer</li>
                        <li>SWK Lighthouse</li>
                        <li>USCCB-TN</li>
                    </ol>
                </p>
                <p>Vesta now partially supports the following new shelters:
                    <ol>
                        <li>Youth Care</li>
                        <li>Cayuga</li>
                        <li>USCCB-VA</li>
                        <li>Holy Family</li>
                        <li>Cunningham Children’s House</li>
                        <li>USCCB-PA</li>
                    </ol>
                </p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />
                
                <h3>Vesta 0.3.11.8 Hotfix 3</h3>
                <p>Release Date: 01/07/2022</p>

                {/*Features*/}
                <h4>Features</h4>

                <b>Milestone Event Date Validations</b>
                <p>Vesta now limits the date entered for a milestone event to 1 year from the current date.  For the milestone Fingerprints Initiated with Vendor, the Appointment Date can be up to 1 year in advance of the current date.</p>

                <b>Language – UC Demographics</b>
                <p>The language field is now an optional field. When manually entering a case, if the field is left blank, Language will now be set to Unknown.  When importing cases, if the Language cell is empty, it will be set to Unknown in Vesta.</p>

                <b>Sponsor Category – UC Demographics</b>
                <p>The Sponsor Category will be presented with capital letters where applicable to be in line with ORR.</p>

                <b>New Milestone Events Supported in Case Imports</b>
                <p>3 new milestone events are now available when importing cases:
                    <ol>
                        <li>Initial Intake Assessment Completed</li>
                        <li>UAC Assessment Completed</li>
                        <li>Risk Assessment Completed</li>
                    </ol>
                </p>

                {/*Known Issues*/}
                <h4>Known Issues</h4>

                <b>Vesta rolling back 1 day for Arrived at Shelter & Staff Assigned milestone dates when importing new cases.</b>
                <p>A workaround has been created where the import process adds 12 hours to the milestone dates entered in the import spreadsheet to combat the issue.</p>

                {/*Reporting Updates*/}
                <h4>Reporting Updates</h4>

                <b>Follow Up Tracker and Follow Up Completed Reports</b>
                <p>Multiple issues resolved and enhancements:
                    <ol>
                        <li>Fix the date filter - show one extra day to prevent issues with missing Cases (due to time comparison)</li>
                        <li>Remove Cases with "Initial Date" from the bottom tables in both reports</li>
                        <li>Remove "Follow Up +X" columns from the bottom tables in both reports</li>
                        <li>Add Case counts by Shelter</li>
                        <li>Add Case count columns to all tables in both reports</li>
                        <li>Update the names for the top tables to match the Report name for clarity</li>
                    </ol>
                </p>

                <b>Case Age Report</b>
                <p>Replace the Active Breakdown pie chart with the Case Status filter</p>

                {/*Miscellaneous*/}
                <h4>Miscellaneous</h4>

                <b>Case Import Template Updated</b>
                <p>The import template has been updated to support new milestone event dates. The order of columns has been optimized. New columns have been added to assist with manual data entry and filtering processes but are not used for importing:
                    <ol>
                        <li>Senior Lead Name</li>
                        <li>Lead Case Manager Name</li>
                        <li>Case Manager Name</li>
                        <li>Data Entry Specialist Name</li>
                    </ol>
                </p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />
                
                <h3>Vesta 0.3.11.7 Out of Band</h3>
                <p>Release Date: 01/07/2022</p>

                {/*Reporting Updates*/}
                <h4>Reporting Updates</h4>

                <b>Shelter Filter Added</b>
                <p>All Daily and Operational Reports have a light blue Shelter filter across the title.</p>

                <b>"Follow Up Tracker" Updated</b>
                <p>Added +28, +35, +42, +49 days. Two sections: Initial UC Interview with CM Completed/Missing.</p>

                <b>"Follow Ups Completed" Added</b>
                <p>Copy of "Follow Up Tracker" and includes EventDateTime and Comments for CM/UC weekly meeting completed.</p>

                <b>"Case Loads - CMs" Updated</b>
                <p>Add "# Active (not submitted)" column.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />

                <h3>Vesta 0.3.11.6 Hotfix 2</h3>
                <p>Release Date: 01/04/2022</p>

                {/*Features*/}
                <h4>Features</h4>

                <b>New shelter locations added</b>
                <p>Vesta now supports the following new shelters:
                    <ol>
                        <li>Alba Care Services</li>
                        <li>Rite of Passage</li>
                        <li>SWK Estrella</li>
                        <li>SWK Fortaleza</li>
                        <li>SWK Las Palmas</li>
                    </ol>
                </p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />
                
                <h3>Vesta 0.3.11.5 Hotfix 1</h3>
                <p>Release Date: 12/10/2021</p>

                {/*Known Issues*/}
                <h4>Known Issues</h4>

                <b>During Case Import, the date time zones are not handled properly by the system</b>
                <p>Milestone dates can show up a day before, causing confusion, due to the mishandling of timezones. A workaround to address this will add 12 hours to account for the timezones in the U.S. and allow the date portion to match the intended Milestone Event Date Time.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />
                
                <h3>Vesta 0.3.11.1</h3>
                <p>Release Date: 12/03/2021</p>

                {/*Known Issues*/}
                <h4>Known Issues</h4>

                <b>Comment Fields Limited to 2,000 characters</b>
                <p>The comment field for all milestones are limited to 2,000 characters.</p>

                {/*Features*/}
                <h4>Features</h4>

                <b>New Milestones Added</b>
                <p>Two new milestones have been added to the Vesta case management process:
                    <ol>
                        <li>Initial UC interview with CM</li>
                        <li>CM/UC weekly meeting completed</li>
                    </ol>
                </p>

                <b>Improved Case Searching</b>
                <p>The search field has been improved to support Case Number, A Number, First Name and Last Name search capabilities.</p>

                {/*Reporting Updates*/}
                <h4>Reporting Updates</h4>

                <b>Uninitiated Status now includes new milestone</b>
                <p>The Power BI Reports are updated to include the milestone "Initial UC interview with CM" in Uninitiated Status</p>

                {/*Miscellaneous*/}
                <h4>Miscellaneous</h4>

                <b>Top Menu Bar</b>
                <p>The top menu bar has been locked in place when scrolling through case details for easier navigation.</p>

                <b>Milestone Title in Popup Windows</b>
                <p>When adding/editing a milestone, the milestone name is presented in the popup window.</p>

                <b>Employee Email Required for Importing</b>
                <p>The default template for importing employees has been updated.  All required fields are now in bold. </p>

                <b>Employee Number Formatted when Importing</b>
                <p>To maintain consistency with Workday, any employee number less than 6 digits will be prefixed with 0’s when imported into Vesta.</p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />
                
                <h3>Vesta 0.3.10</h3>
                <p>Release Date: 11/12/2021</p>

                {/*Known Issues*/}
                <h4>Known Issues</h4>

                <b>New Case Shelter Field. Fixed</b>
                <p>When creating a new case, the shelter field was not carried over to the main case details screen when the user saves the initial case info.</p>

                <b>Adding New Employees. Fixed</b>
                <p>An employee email address is now a required field for both importing and creating new employees.</p>

                {/*Features*/}
                <h4>Features</h4>

                <b>Case List, My Cases & Milestone Lists </b>
                <p>The main list windows have now been banded to enhance visual aid. Also, the Add/Edit Clear links have been moved to the right of the Milestone description for ease of use in the Case Details Milestone section</p>

                {/*Reporting Updates*/}
                <h4>Reporting Updates</h4>

                <b>New LOS Reports based on ORR's LOS Categories</b>
                <p>New Categories:
                    <ol>
                        <li>Cat 1 LOS 14+ Days</li>
                        <li>Cat 1 & 2 LOS 30+ Days</li>
                        <li>LOS 15+ Days</li>
                        <li>LOS 30+ Days</li>
                    </ol>
                </p>

                {/*Miscellaneous*/}
                <h4>Miscellaneous</h4>

                <b>Remove unused Contact Log tab</b>
                <p>The Contact Log tab has been removed on the case milestone details </p>

                {/*-----------------------------------------------------------------------------------------------------------*/}
                <hr />
                
                <h3>Vesta 0.3.9</h3>
                <p>Release Date: 10/30/2021</p>

                {/*Features*/}
                <h4>Features</h4>
                
                <b>New Vesta Data Field: Senior Lead</b>
                <p>When editing an existing case there is the ability to identify the assigned Senior Lead. In the Case Management Queue (Case List) and the My Cases views, the Senor Lead, Lead Case Manager, and Case Manager are listed in the table.</p>

                <b>New System Admin Role Capability: Import Cases</b>
                <p>The new Import Cases functionality supports the bulk import of cases from an Excel spreadsheet.  When accessing the Import Cases page, the System Admin is provided with guidance on how successfully import multiple cases into the Vesta Database.  A template Excel spreadsheet is also provided.  Data validation, such as duplicate entries, invalid data elements etc. is provided prior to the import process, allowing the user “rectify” any data inconsistencies before executing the import.</p>

                <b>New System Admin Role Capability: Import Employees</b>
                <p>The new Import Employees functionality supports the bulk import of TPG employees from an Excel spreadsheet.  When accessing the Import Employees page, the System Admin is provided with guidance on how successfully import multiple employees into the Vesta Database.  A template Excel spreadsheet is also provided.  Data validation, such as duplicate entries, invalid data elements etc. is provided prior to the import process, allowing the user “rectify” any data inconsistencies before executing the import.</p>

                {/*Known Issues*/}
                <h4>Known Issues</h4>

                <b>Create New Case</b>
                <p>When creating a new case, the case manager and shelter/region fields are not carried over to the main case details screen when the user saves the initial case data. Re-entering the case manager name and shelter/region details and saving from the main case data screen works as expected.</p>

                <b>User Not in the Vesta Database</b>
                <p>When logging into Vesta, if the user is not registered within the system, the error message “System error related to the API and/or database. Click on the Support menu link and email support.” will be displayed in the Case Management Queue and the My Cases List.  The user should access contact Support to log the issue.</p>

                {/*Reporting Updates*/}
                <h4>Reporting Updates</h4>

                <b>Power BI Report updated</b>
                <p>Reports have been reordered and recategorized based on latest common usage and unused reports have been removed.</p>

                <b>Case Manager Snapshot "Case Update Date" column has the wrong date time</b>
                <p>Fixed the column and (for all affected reports) rename from "Case Update" to "Latest Case Milestone" and "Case Update EventDateTime" to "Latest Milestone Date".</p>

                {/*Miscellaneous*/}
                <h4>Miscellaneous</h4>
                <b>The Page Footer now shows the active users’ name.</b>
                <p>When the mouse is positioned over the user name the roles and facility within Vesta that assigned to the user are displayed.</p>

                <b>New Vesta Support page</b>
                <p>Clicking the Vesta Version Number or clicking the Support menu option accesses the Vesta Support page.</p>

            </div>
        );
    }
}